import React, { useState, useEffect, useCallback } from "react";
import { Button } from "../../../../components/Button/Button";
import { http } from "../../../../utils/httpCommon";
import { TextInput } from "../../../../components/TextInput/TextInput";
import { useForm } from "react-hook-form";
import useFetchData from "../../../../hooks/FetchData";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Controller } from "react-hook-form";
import LoadingCircle from "../../../../components/LoadingCircle/LoadingCircle";
import porfileImg from "../../../../assets/profile.png";
import backButton from "../../../../assets/backButton.png";
import { Link } from "react-router-dom";
import { toast } from "react-hot-toast";
import backArrow from "../../../../assets/backarrow.svg";
// import { SelectedLocationContext } from "../../../../Context/LocationProvider";
import { Checkbox, PasswordInput } from "@mantine/core";

const NewRentContractModalStep2 = ({
  opened,
  setOpened,
  fetchData,
  data,
  rentContractModalStep,
  setRentContractModalStep,
}) => {
  const [step, setStep] = useState(0);
  const [payWithCredit, setPayWithCredit] = useState(false);
  // const { selectedLocation } = useContext(SelectedLocationContext);
  const { response: paymentMethods, loading: loadingPaymentMethod } = useFetchData(
    `rent-contracts/${data?._id}/payment-method-accounts`,
  );

  // const validateAmount = (initialDepositAmount) => {
  //   if (payWithCredit) {
  //     return yup.number().optional();
  //   } else {
  //     return yup
  //       .number()
  //       .typeError("Amount paid is required")
  //       .integer()
  //       .typeError("Amount paid should be an integer")
  //       .test(
  //         "is-greater-than-or-equal",
  //         "Amount should be greater than or equal to initial deposit amount",
  //         (value) => {
  //           if (!value) {
  //             return true;
  //           }
  //           return value >= initialDepositAmount;
  //         }
  //       )
  //       .required("Amount paid is required");
  //   }
  // };

  const paymentMethodValidation = useCallback(() => {
    if (payWithCredit) {
      return yup.string().optional();
    } else {
      return yup
        .number()
        .typeError("Payment method is a required field")
        .required("Payment method is a required field");
    }
  }, [payWithCredit]);

  // const amountValidation = useCallback(() => {
  //   if (payWithCredit) {
  //     return yup.string().optional();
  //   } else {
  //   }
  // }, [data?.initialDepositAmount, payWithCredit]);

  const schema = yup
    .object({
      paymentMethod: paymentMethodValidation(),
      amount: yup
        .number()
        .typeError("Amount paid is required")
        // .integer()
        // .typeError("Amount paid should be an integer")

        // .test(
        //   "is-greater-than-or-equal",
        //   "Amount should be greater than or equal to initial deposit amount",
        //   (value) => {
        //     if (!value) {
        //       return true;
        //     }
        //     return value >= data?.initialDepositAmount;
        //   }
        // )
        .required("Amount paid is required"),
      needInvoice: yup.bool().default(false),
      adminPassword:
        step === 0
          ? yup.string()
          : yup
              .string()
              .typeError("Admin password paid is required")
              .required("Admin password is required"),
    })
    .required();
  const {
    register,
    handleSubmit,
    control,
    getValues,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const paymentMethod = watch("paymentMethod");

  useEffect(() => {
    paymentMethodValidation();
    // amountValidation();
    watch("paymentMethodId");
    watch("amount");
  }, [
    payWithCredit,
    watch,
    paymentMethodValidation,
    // amountValidation
  ]);

  const [isSubmitted, setIsSubmitted] = useState(false);
  const onSubmit = async (passedData) => {
    if (step === 0) {
      setStep(1);
    } else {
      setIsSubmitted(true);
      try {
        await http.put(
          `/rent-contracts/${data._id}/pay`,
          payWithCredit
            ? JSON.stringify({
                adminPassword: passedData.adminPassword,
                rentPaymentUsingCredit: payWithCredit,
                paidAmount: passedData?.amount,
              })
            : JSON.stringify({
                paidAmount: passedData.amount,
                paymentMethodId: parseInt(passedData.paymentMethod),
                adminPassword: passedData.adminPassword,
                rentPaymentUsingCredit: payWithCredit,
              }),
        );

        toast.success("Rent contract payment created successfully");
        setIsSubmitted(false);
        fetchData();
        setOpened(false);
        reset();
      } catch (error) {
        toast.error(error?.response?.data?.message || error.message || "Something went wrong");
        setIsSubmitted(false);
      }
    }
  };

  // const handleOnClose = () => {
  //   setOpened(false);
  //   reset();
  // };

  console.log("error", errors);

  return (
    <>
      {loadingPaymentMethod ? (
        <div className="min-h-[400px]">
          <LoadingCircle />
        </div>
      ) : (
        <>
          {step === 0 ? (
            <div className="flex flex-col justify-start px-4 min-h-[400px]">
              <button
                onClick={() => setRentContractModalStep(1)}
                className="flex gap-[10px] items-center"
              >
                <img src={backButton} alt="back-button" className="w-[40px] h-[40px]" />
                {/* <div className="text-[20px]  " style={{ fontFamily: "gilroy-bold" }}>
             Booking
          </div> */}
              </button>
              <div className="text-[24px]  mt-3 " style={{ fontFamily: "gilroy-bold" }}>
                Confirm Rent Contract
              </div>

              <div
                className="text-[20px] mt-3 text-[#797979] "
                style={{ fontFamily: "gilroy-bold" }}
              >
                Contract Number
              </div>
              <div className="text-[28px] font-bold" style={{ fontFamily: "gilroy-bold" }}>
                {data?.rentNumber}
              </div>

              <div className="text-[18px] mt-[15px] " style={{ fontFamily: "gilroy-bold" }}>
                Basic info
              </div>
              <div className=" text-[#797979] font-bold text-[18px]  ">Location</div>
              <div className="border-[1px] rounded-[10px] w-full">
                <div className="flex items-center gap-[20px] p-1 h-full ">
                  <img
                    src={data?.facility[0]?.location?.mainImage}
                    alt={data?.facility[0]?.location?.name}
                    className="w-[90px] h-[65px] object-cover rounded-[10px]"
                  />
                  <div className="text-[16px]  h-full flex flex-col gap-3">
                    <div style={{ fontFamily: "gilroy-bold" }}>
                      {data?.facility[0]?.location?.name}
                    </div>
                    <Link
                      to={`/inventory/location/${data?.facility[0]?.location?._id}`}
                      className=" text-[#34B2C0] underline text-[14px]"
                    >
                      Quick View
                    </Link>
                  </div>
                </div>
              </div>

              <div className="mt-5 text-[#797979]  text-[18px]   font-bold">Facilities</div>
              <div className="flex  gap-2 flex-wrap">
                {data?.facility.map((facility) => (
                  <div className="border-[1px] rounded-[10px] w-full">
                    <div className="flex items-center gap-[20px] p-1 h-full ">
                      <img
                        src={facility?.mainImage}
                        alt={facility?.name}
                        className="w-[90px] h-[65px] object-cover rounded-[10px]"
                      />
                      <div className="text-[16px]  h-full flex flex-col gap-3">
                        <div style={{ fontFamily: "gilroy-bold" }}>{facility?.name}</div>
                        <Link
                          to={`/inventory/facility/${facility?._id}`}
                          className=" text-[#34B2C0] underline text-[14px]"
                        >
                          Quick View
                        </Link>
                      </div>
                    </div>
                  </div>
                ))}
              </div>

              <div className="mt-3 text-[#797979]  text-[18px]  font-bold">Client information</div>
              <div className="border-[1px] rounded-[10px] w-full">
                <div className="flex items-center gap-[20px] p-1 h-full ">
                  <img
                    src={
                      data?.company?.leader?.porfileImg
                        ? data?.company?.leader?.porfileImg
                        : porfileImg
                    }
                    alt={data?.company?.leader?.name}
                    className="w-[65] h-[60px] object-contain rounded-[10px]"
                  />
                  <div className="text-[16px]  h-full flex flex-col gap-1">
                    <div style={{ fontFamily: "gilroy-bold" }}> {data?.company?.leader?.name}</div>
                    <Link className="  text-[14px] text-[#797979] ">
                      Member at {data?.company?.name}
                    </Link>
                  </div>
                </div>
              </div>

              {!payWithCredit && (
                <div className="text-[20px]  mt-3 mb-2 " style={{ fontFamily: "gilroy-bold" }}>
                  Payment
                </div>
              )}
              <form className="w-full " onSubmit={handleSubmit(onSubmit)}>
                <div className="mt-4 mb-2">
                  {!payWithCredit && (
                    <div>
                      <FormControl fullWidth sx={{ border: "none !important" }}>
                        <InputLabel> Payment Method</InputLabel>
                        <Controller
                          control={control}
                          name="paymentMethod"
                          render={({ field: { onChange, onBlur, value, name, ref } }) => (
                            <Select
                              sx={{
                                height: "40px !important",
                                color: "black",
                                ".MuiOutlinedInput-notchedOutline": {
                                  borderColor: "#EFEFEF",
                                },
                              }}
                              onBlur={onBlur} // notify when input is touched
                              onChange={onChange} // send value to hook form
                              variant="outlined"
                              labelId="Payment Method-label"
                              id="Payment Method"
                              // value={value}
                              label="Payment Method"
                              inputRef={ref}
                              defaultValue={paymentMethod}
                            >
                              <MenuItem value="">
                                {" "}
                                <em>None</em>{" "}
                              </MenuItem>
                              {paymentMethods.map((l) => (
                                <MenuItem key={l?.qboId} value={l?.qboId}>
                                  {l?.name}
                                </MenuItem>
                              ))}
                            </Select>
                          )}
                        />
                      </FormControl>
                      <p className="text-red-500">{errors.paymentMethod?.message}</p>
                    </div>
                  )}
                </div>

                <div className="mt-4">
                  <div className="mt-[15px]">
                    <div
                      className=" text-[#011516] text-[18px] font-bold  mb-4"
                      style={{ fontFamily: "gilroy-bold" }}
                    >
                      Total
                    </div>
                    <div className="bg-[#F6F8F7] w-full rounded-[10px]  flex flex-col p-[16px] gap-2 ">
                      <div className="flex justify-between">
                        <span className="font-bold text-[16px]">Rent Price</span>
                        <span className="text-[#797979] text-[16px]">
                          {data?.rentTotal}
                          EGP
                        </span>
                      </div>

                      {data?.discount && Number(data?.discount) > 0 ? (
                        <div className="flex justify-between">
                          <span className="font-bold text-[16px]">Discount</span>
                          <span className="text-[#797979] text-[16px]">
                            -{Number(data?.discount).toFixed(2)} EGP
                          </span>
                        </div>
                      ) : null}

                      <div className="flex justify-between text-[16px]">
                        <span className="font-bold">WHT</span>
                        <span className="text-[#797979] ">
                          {Number(data?.whtAmount).toFixed(2)}
                          EGP
                        </span>
                      </div>

                      <div className="flex justify-between text-[16px]">
                        <span className="font-bold">VAT</span>
                        <span className="text-[#797979] ">
                          {Number(data?.taxAmount).toFixed(2)}
                          EGP
                        </span>
                      </div>

                      {/* {data?.securityDeposit && Number(data?.securityDeposit) > 0 ? (
                        <div className="flex justify-between text-[16px]">
                          <span className="font-bold">Security Deposit Amount</span>
                          <span className="text-[#797979] ">
                            {data?.securityDeposit}
                            EGP
                          </span>
                        </div>
                      ) : null} */}

                      {data?.vasTotal && Number(data?.vasTotal) > 0 ? (
                        <div className="flex justify-between text-[16px]">
                          <span className="font-bold">VAS Total</span>
                          <span className="text-[#797979] ">
                            {Number(data?.vasTotal).toFixed(2)}
                            EGP
                          </span>
                        </div>
                      ) : null}

                      {data?.insurance && !isNaN(data?.insurance) && Number(data?.insurance) > 0 ? (
                        <div className="flex justify-between text-[16px]">
                          <span className="font-bold">Insurance </span>
                          <span className="text-[#797979] ">
                            {Number(data?.insurance)}
                            EGP
                          </span>
                        </div>
                      ) : null}

                      <div className="flex flex-col gap-4 mt-2">
                        <span className="font-bold text-[18px]">Total</span>
                        <span
                          className="text-[40px] text-[#011516]"
                          style={{ fontFamily: "gilroy-bold" }}
                        >
                          {data?.total}
                          EGP
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                {/* {!payWithCredit && ( */}
                <div className="mt-2 ">
                  <TextInput label={"Amount paid"} {...register("amount")} />
                  {/* <div className="text-[16px] mt-1 text-[#797979]">
                    Minimum amount paid = {data?.initialDepositAmount}
                  </div> */}
                  <p className="text-red-500 mt-4">{errors.amount?.message}</p>
                </div>
                {/* )} */}

                <div className="flex items-center gap-2 my-4">
                  <Checkbox
                    label="Pay with credit"
                    checked={payWithCredit}
                    onChange={(event) => setPayWithCredit(event.currentTarget.checked)}
                    color="rgba(0, 0, 0, 1)"
                    size={"md"}
                  />
                </div>

                <div className="mt-6 mb-6 flex justify-end gap-10">
                  <Button onClick={() => setOpened(false)} secondary={true} invert={true}>
                    Cancel
                  </Button>
                  <Button type="submit" secondary={true}>
                    Pay now
                  </Button>
                </div>
              </form>
            </div>
          ) : (
            // Confirm
            <div className="px-10 relative ">
              <div
                onClick={() => setStep(0)}
                className="-top-10  flex cursor-pointer  justify-center items-center w-[40px] h-[40px] rounded-full bg-[#EFEFEF] hover:[##686868] "
              >
                <img src={backArrow} alt="back" className="w-[8px] h-[10px] mt-0 object-contain" />
              </div>
              <div className="flex justify-between ">
                <div
                  className="text-[30px] "
                  style={{
                    fontFamily: "gilroy-bold",
                  }}
                >
                  Security Confirmation
                </div>
              </div>

              <div className="my-2 flex flex-col gap-3">
                <div className="text-[#797979] text-[16px] font-semibold">Client information</div>
                <Link
                  to={`/community/client/${data?.company?._id}`}
                  className="border-[#E4E4E4] border-[1px] rounded-[10px] p-1 flex gap-3"
                >
                  <img
                    src={data?.company?.logo}
                    alt={data?.company?.name}
                    className="rounded-[10px] w-[65px] object-cover h-[65px] "
                  />
                  <div className="flex flex-col justify-center w-full  ">
                    <div className="text-[18px] " style={{ fontFamily: "gilroy-bold" }}>
                      {data?.company?.name}
                    </div>
                  </div>
                </Link>
              </div>

              <div className="text-[18px]">
                I Admin confirm the payment of "
                <span className="font-bold">{getValues()?.amount}</span>" by {data?.company?.name}{" "}
                for Booking facility at {data?.facility?.name}
              </div>

              <form className="w-full " onSubmit={handleSubmit(onSubmit)}>
                <div className="mt-10 ">
                  <PasswordInput
                    required
                    size="lg"
                    radius={"md"}
                    placeholder="Admin Passowrd"
                    {...register("adminPassword")}
                  />
                  <p className="text-red-500 mt-4 ">{errors.adminPassword?.message}</p>
                </div>

                <div className="flex w-full justify-end  mt-6 gap-5">
                  <Button secondary={true} invert={true} onClick={() => setOpened(false)}>
                    Cancel
                  </Button>

                  <Button loading={isSubmitted} type="submit" secondary={true}>
                    Confirm
                  </Button>
                </div>
              </form>
            </div>
          )}
        </>
      )}
      {/* ) : (
        <LoadingCircle />
      )} */}
    </>
  );
};

export default NewRentContractModalStep2;
