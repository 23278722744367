import React, { useCallback, useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Button } from "../../../components/Button/Button";
import { http } from "../../../utils/httpCommon";
import { debounce } from "lodash";
import Table from "../../../components/DataGrid/Table";
import { toast } from "react-hot-toast";
import { SelectedLocationContext } from "../../../Context/LocationProvider";
import moment from "moment";
import { useAuthUser } from "react-auth-kit";
import phoneICon from "../../../assets/icons/call.png";

const Receivables = () => {
  const { selectedLocation } = useContext(SelectedLocationContext);
  const auth = useAuthUser();
  const [pageState, setPageState] = useState({
    isLoading: false,
    data: [],
    total: 0,
    page: 1,
    pageSize: 10,
  });
  const [searchQuery] = React.useState("");
  ///onFilterChange
  const onFilterChange = useCallback(
    async (filterModel) => {
      try {
        let searchVal = filterModel?.items[0]?.value;

        if (searchVal?.length > 0) {
          setPageState((old) => ({
            ...old,
            isLoading: true,
          }));

          const res = await http.get(
            `/receivables?page=${pageState.page}&limit=${pageState.pageSize}&location=${selectedLocation}&search=${searchQuery}`,

            JSON.stringify({
              page: pageState.page,
              limit: pageState.pageSize,
              name: searchVal,
            }),
          );

          const rowsData = res.data?.data?.map((d) => ({
            id: d._id,
            ...d,
          }));

          setPageState((old) => ({
            ...old,
            isLoading: false,
            data: rowsData,
            //get from another api
            total: res?.data?.metadata?.[0]?.count,
          }));
        } else {
          debounced();
        }
      } catch (error) {
        console.error(error);
        toast.error(error.response?.data?.message || error.message || "Something went wrong");
      }
    },
    [pageState.page, pageState.pageSize, selectedLocation],
  );

  const columns = [
    {
      field: "client",
      headerName: "Client",
      width: 180,
      filterable: false,
      // editable: true,
      renderCell: (params) => {
        return (
          <Link
            to={`/community/client/${params.row?.client?._id}`}
            className="flex flex-col gap-[5px] h-[80px] text-[16px]  justify-center hover:text-[#34B2C0]"
          >
            <div className="font-semibold">{params.row?.client?.name}</div>

            {params.row?.client?.phone && (
              <div className="flex items-center">
                <img src={phoneICon} className="w-[13.5px]" alt={params.row?.client?.name} />
                {params.row?.client?.phone}
              </div>
            )}
          </Link>
        );
      },
    },
    {
      field: "finance.amount",
      headerName: "Amount",
      width: 150,
      renderCell: (params) => {
        return (
          <div className="flex gap-[10px] items-center text-[16px] ">
            {params?.row?.finance?.amount}
          </div>
        );
      },
    },
    {
      field: "date",
      headerName: "Date",
      minWidth: 150,
      maxWidth: 200,
      renderCell: (params) => {
        return (
          <div className="flex gap-[10px] items-center text-[16px]">
            {moment(params?.row?.serviceDate).format("MMM Do YYYY")}
          </div>
        );
      },
    },

    {
      field: "service",
      headerName: "Service",
      width: 180,
      renderCell: (params) => {
        return <div className="font-semibold text-base">{params?.row?.serviceType}</div>;
      },
    },
    {
      field: "expectedDueDate",
      headerName: "Expected Due Date",
      width: 150,
      renderCell: (params) => {
        return (
          <div className="flex gap-[10px] items-center text-[16px]">
            {params?.row?.expectedDueDate
              ? moment(params?.row?.expectedDueDate).format("MMM Do YYYY")
              : "N/A"}
          </div>
        );
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 480,
      renderCell: ({ row }) => {
        return (
          <>
            {auth().role !== "Land Lord" && (
              <div className="flex gap-5">
                <Button
                  secondary={true}
                  loading={paying}
                  onClick={() => {
                    onPayNow(row._id);
                  }}
                >
                  Pay Now
                </Button>
              </div>
            )}
          </>
        );
      },
    },
  ];

  const fetchData = async () => {
    try {
      setPageState((old) => ({
        ...old,
        isLoading: true,
      }));
      const res = await http.get(
        `/receivables?page=${pageState.page}&limit=${pageState.pageSize}&status=unpaid&location=${selectedLocation}&search=${searchQuery}`,
      );
      const rowsData = res.data?.data?.data?.map((d) => ({
        id: d._id,
        ...d,
      }));
      setPageState((old) => ({
        ...old,
        isLoading: false,
        data: rowsData,
        total: res?.data?.data?.length,
      }));
    } catch (error) {
      toast.error(error.response?.data?.message || error.message || "Something went wrong");
    }
  };
  const debounced = useCallback(debounce(fetchData, 1000), [pageState]);

  useEffect(() => {
    fetchData();
  }, [pageState.page, pageState.pageSize, selectedLocation, searchQuery]);

  const [paying, setPaying] = useState(false);

  const onPayNow = async (id) => {
    try {
      setPaying(true);
      await http.put(`/receivables/${id}/pay`);
      toast.success("Booking has been paid successfully");
      fetchData();
    } catch (error) {
      toast.error(error.response?.data?.message || error.message || "Something went wrong");
    } finally {
      setPaying(false);
    }
  };
  return (
    <div>
      <Table
        rowCount={pageState.total}
        isLoading={pageState.isLoading}
        data={pageState?.data}
        page={pageState.page - 1}
        pageSize={pageState.pageSize}
        onPageChange={(newPage) => setPageState((old) => ({ ...old, page: newPage + 1 }))}
        removeActionButton
        onPageSizeChange={(newPageSize) =>
          setPageState((old) => ({ ...old, pageSize: newPageSize }))
        }
        columns={columns}
        onFilterChange={onFilterChange}
        removeSearch
      />
    </div>
  );
};

export default Receivables;
